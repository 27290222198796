import React, { useState } from 'react'
import "./ServiceContent.css";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"

function ServiceContent(props) {
    console.log(props)
    const data = props;

    return (
        <div className=" w-full md:w-2/3 pb-4 order-1 md:order-2">
            {props.featured?.localFile?.childImageSharp?.gatsbyImageData && (
                <GatsbyImage
                    image={props.featured.localFile.childImageSharp.gatsbyImageData}
                    alt={props.featured.altText}
                    className="m-4" />
            )}

            <div className="p-4 service-editor" dangerouslySetInnerHTML={{ __html: data.content }} />

            <div className="pt-8">
                <div>
                    <div className="fake-lt">
                        <h2>{data.language === "EL" ? "Τελευταίες δράσεις" : "Latest Actions"}</h2>
                    </div>


                    <div className="w-full pl-0 pb-8 ">

                        {(data.relatedPosts && data.relatedPosts.length) > 0 && (
                            <div className="w-full flex flex-wrap" >
                                {data.relatedPosts.length > 0 && data.relatedPosts.map((rp, i) => (
                                    <Link to={data.language === "EL" ? "/blog/" + rp.slug : "/en/blog/" + rp.slug} className="w-full md:w-1/3 pr-0 md:pr-4 pt-4 s-r-cont" key={`related-post-${i}`}>
                                        <div style={{ display: "grid" }}>
                                            {rp.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData && (
                                                <GatsbyImage
                                                    style={{
                                                        gridArea: "1/1",
                                                    }}
                                                    loading={"lazy"}
                                                    image={rp.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                                                    alt={rp.featuredImage.node.altText}
                                                    formats={["auto", "webp", "avif"]}
                                                />
                                            )}

                                            <div
                                                style={{
                                                    // By using the same grid area for both, they are stacked on top of each other
                                                    gridArea: "1/1",
                                                    position: "relative",
                                                    // This centers the other elements inside the hero component
                                                    placeItems: "center",
                                                    display: "grid",
                                                }}
                                            >
                                                <div className="serv-rel-h4">
                                                    <h4>{rp.title}</h4>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div>
                                            {rp.featuredImage && (
                                                <GatsbyImage
                                                    image={rp.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                                                    alt={rp.featuredImage.node.altText}
                                                    className="w-full"
                                                />
                                            )}

                                        </div>
                                        <div className="">
                                            <h4>{rp.title}</h4>
                                        </div> */}
                                    </Link>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ServiceContent;